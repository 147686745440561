<template>
  <div class="apartments-view">
    <div v-if="apartmentsList && Object.values(apartmentsList).length" class="row">
      <div v-for="(apartments, objectName) in apartmentsList" class="col-md-6 col-xxl-4">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-body">
            <div class="d-flex justify-content-between flex-column h-100">
              <div class="h-100">
                <div class="d-flex flex-column flex-center">
                  <div class="card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pb-1">
                    {{ objectName }}
                  </div>
                </div>
                <div class="overflow-auto pt-5 max-h-300px">
                  <div v-for="(apartment, idx) in apartments"
                       :key="idx"
                       class="d-flex align-items-center"
                       :class="{'pt-3': idx > 0, 'pb-3 border-bottom': idx < apartments.length -1}"
                  >
                    <div class="symbol symbol-45 symbol-light mr-4">
                      <span class="symbol-label">
                        <span class="fs-lg" :class="getApartmentIcon(apartment.status_code)"></span>
                      </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1">
                      <router-link
                          v-if="apartment.status_code === 'ownership' && apartment.id !== '0' && apartment.id !== null"
                          :to="`/apartments/${apartment.id}`"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                      >
                        <a :href="`/apartments/${apartment.id}`"
                           class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"
                           @click="navigate"
                        >
                          {{ apartment.name }}
                        </a>
                      </router-link>
                      <div v-else>
                        <span class="text-dark-75 mb-1 font-size-lg font-weight-bolder">
                          {{ apartment.name }}
                        </span>
                      </div>

                      <div class="d-flex">
                        <span class="text-muted font-weight-bold">
                          {{ $t('label.block') }}:
                          <span class="text-dark-75 font-weight-bolder">{{ apartment.block }}</span>
                        </span>
                        <span class="text-muted font-weight-bold ml-10">
                          {{ $t('label.number') }}:
                          <span class="text-dark-75 font-weight-bolder">{{ apartment.number }}</span>
                        </span>
                      </div>
                    </div>
                    <span
                        class="font-weight-bolder label label-xl label-inline px-3 py-5 min-w-45px"
                        :class="`label-light-${getStatusColor(apartment.status_code)}`"
                        v-b-tooltip.left.hover :title="apartment.status_description"
                    >
                      {{ apartment.status_name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="apartmentsList && !Object.values(apartmentsList).length" class="lead text-center mt-5">
      {{ $t('description.no_apartments') }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  async beforeMount() {
    await this.$store.dispatch('apartmentsStore/GET_LIST');
  },
  computed: {
    ...mapGetters({
      apartmentsList: 'apartmentsStore/LIST'
    })
  },
  methods: {
    getApartmentIcon(status) {
      // for_sale / booked / blocked / registration / sold / canceling / ownership
      let icon;
      switch (status) {
        case 'registration':
          icon = 'fal fa-file-signature';
          break;
        case 'canceling':
          icon = 'fal fa-business-time';
          break;
        case 'sold':
          icon = 'fal fa-badge-dollar';
          break;
        case 'blocked':
          icon = 'fal fa-ban';
          break;
        default:
          icon = 'fal fa-building';
          break;
      }

      return icon;
    },
    getStatusColor(status) {
      // for_sale / booked / blocked / registration / sold / canceling / ownership
      let color;
      switch (status) {
        case 'ownership':
          color = 'success';
          break;
        case 'registration':
          color = 'warning';
          break;
        case 'canceling':
          color = 'danger';
          break;
        case 'sold':
        case 'blocked':
          color = 'dark';
          break;
        default:
          color = 'primary';
          break;
      }

      return color;
    }
  },
  beforeDestroy() {
    this.$store.commit('apartmentsStore/CLEAR_DATA');
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  max-height: 575px;
}
</style>